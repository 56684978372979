import React from "react";

export default function YouthIssues() {
  return (
    <div>
      {/* Youth Issues */}
      <div className="mt-10 mb-10">
        <h1 className="my-4 text-center text-3xl font-bold text-gray-700">
          Issues Youth Are Facing
        </h1>
        <ul className="flex flex-wrap justify-center">
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">1 in 4 children live in a fatherless home</p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white  p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">1 in 4 teen girls has at least one STD</p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">
              47% of youth use an illegal drug by the time they graduate high
              school
            </p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">
              1 in 4 women will have an abortion by the age of 45
            </p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">
              Average age of first exposure to pornography is 11
            </p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">
              The second leading cause of death for people aged 15-24 is suicide
            </p>
          </li>
          <li className="mx-8 my-2 w-full rounded-md bg-white p-4 shadow-xl lg:w-[25%]">
            <p className="p-2">
              1 in 3 adolescents is a victim of abuse from a dating partner
              (sexual, physical, emotional, verbal)
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
