import React from "react";
import { Helmet } from "react-helmet";

// import { BiTestTube } from "react-icons/bi";
// import { BsSoundwave } from "react-icons/bs";
// import { GiThreeFriends } from "react-icons/gi";
// import { MdFamilyRestroom } from "react-icons/md";
// import { FaBaby } from "react-icons/fa";

export default function CommunityOutreach() {
  return (
    <div className="my-4 bg-white font-rubik md:mx-auto md:w-1/2">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
        <title>Community Outreach | Pathway Resource Center</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="my-8 text-center text-3xl">Community Outreach</h1>

      <p className="my-8 mx-auto w-[65%] text-center md:h-[70vh]">
        Pathway offers many classes, VBS opportunities, and Hotel Outreach
        programs. A calendar with these events will be coming soon.
      </p>
    </div>
  );
}
