import React from "react";
import { Helmet } from "react-helmet";
import pathway_book from "../assets/pathway_book/pathway_book_pg1.webp";

export default function OrderCurriculum() {
  return (
    <div className="text-center md:mx-auto md:w-1/2">
      <Helmet>
        <title>Order Curriculum | Pathway Resource Center</title>
        <meta
          name="description"
          content="Order bible-based curriculum and lessons for teaching kids life skills and risk avoidance. Lessons cover topics like goal-setting, social media risks, abstinence and drug avoidance, and relationship skills."
        />
      </Helmet>
      <h1 className="px-8 pt-8 text-gray-700 md:px-24">
        Suggested donation for the Pathway Life Skills and Risk Avoidance
        curriculum is $10 per workbook. If you are unable to donate the
        suggested amount, we still want you to have one... Simply put in the
        amount you are able to donate or contact us via email at
        info@PathwayTXK.org
      </h1>
      <button className="ml-2 mt-5 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
        <a href="https://engage.suran.com/pathwayresourcecenter/s/give/gift/80c96cabadbd4f9">
          Order
        </a>
      </button>
      <h1 className="px-8 pt-6 text-gray-700 md:px-24">
        If you are able to contribute more than the suggested donation of $10
        per book, your donation will be used to cover the costs of workbooks for
        individuals or youth groups who are not able to donate at this time.
        Your contribution will also be used for the production of future
        curriculum and programs offered by Pathway.
      </h1>
      <h1 className="py-6 px-8 text-gray-700 md:px-24">
        To order books, click on the Order Now button then select the “Donation
        for Workbook” designation. Please be sure to put the number of workbooks
        you are ordering in the note section. Email us at info@PathwayTXK.org if
        you have any questions.
      </h1>
      <button className="ml-2 mb-8 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
        <a href="https://engage.suran.com/pathwayresourcecenter/s/give/gift/80c96cabadbd4f9">
          Order
        </a>
      </button>
      <button className="ml-2 mb-6 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
        <a href="/contact">Get In Touch</a>
      </button>
      <div className="flex justify-center pb-8">
        <img
          src={pathway_book}
          alt=""
          className=" w-60 rounded-sm"
          loading="lazy"
        />
      </div>
    </div>
  );
}
