import React from "react";

import { BsCalendarCheckFill } from "react-icons/bs";
import { MdSms } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";

export default function StickyMobileFooter() {
  return (
    <div className="fixed bottom-0 flex h-16 w-full justify-between bg-[#457B9D] text-center text-white md:hidden">
      <div className="flex-1 border-r-2 border-t-2 border-white text-3xl">
        <a href="/contact">
          <BsCalendarCheckFill className="mx-auto mt-2" />
        </a>
      </div>
      <div className="flex-1 border-r-2 border-t-2 border-white text-4xl">
        <a href="sms:+18707792229">
          <MdSms className="mx-auto mt-2" />
        </a>
      </div>
      <div className="flex-1 border-t-2 border-white text-4xl">
        <a href="tel:+18707792229">
          <AiFillPhone className="mx-auto mt-2" />
        </a>
      </div>
    </div>
  );
}
