import React from "react";
import Nav from "./nav";
import Footer from "./footer";
import StickyMobileFooter from "./stickymobilefooter";

export default function Layout({ children }) {
  return (
    <div className="">
      <Nav />
      <main className="pt-[90px]">{children}</main> {/* bg-[#457B9D]  */}
      <Footer />
      <StickyMobileFooter />
    </div>
  );
}
