import React from "react";
import { MdSms } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";

export default function Location() {
  return (
    <div className="mx-7 mb-12 mt-12 rounded-md bg-[#457B9D] pt-4 text-center text-white shadow-md md:mx-auto md:w-1/2 lg:mt-24">
      <h1 className="mt-4 mb-4 text-center text-3xl font-bold">Our Location</h1>
      <div>
        <button className="ml-2 mb-6 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
          <a
            href="https://goo.gl/maps/v9kzpMPKtiWpooc77"
            target="_blank"
            rel="noreferrer"
          >
            Get Directions
          </a>
        </button>
        <button className="ml-2 mb-6 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
          <a href="/contact">Get In Touch</a>
        </button>
      </div>
      <h1 className="mb-2 text-xl underline">Pathway Resource Center</h1>
      <h1 className="mb-2 text-sm ">1900 Marietta St, Texarkana, AR 71854</h1>
      <h1 className="mb-2 text-xl underline">Call or Text</h1>
      <div className="mb-4 flex flex-row justify-center gap-3">
        <h1 className="text-sm">870-779-2229</h1>
        <div className="">
          <a href="sms:+18707792229">
            <MdSms className="mx-auto text-xl" />
          </a>
        </div>
        <div className="">
          <a href="tel:+18707792229">
            <AiFillPhone className="mx-auto text-xl" />
          </a>
        </div>
      </div>

      <h1 className="mb-2 text-xl underline">Hours</h1>
      <h1 className="text-sm">Sunday Closed</h1>
      <h1 className="text-sm">Monday 4–7PM</h1>
      <h1 className="text-sm">Tuesday, Wednesday (Classes)</h1>
      <h1 className="text-sm">Thursday 10AM–2PM</h1>
      <h1 className="text-sm">Friday Closed</h1>
      <h1 className="text-sm">Saturday 10AM–2PM</h1>
      <iframe
        defer
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.785574089978!2d-94.0228396877611!3d33.42883427328707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86346be399eebaaf%3A0xa56cc5d6733652a0!2sPathway%20Resource%20Center!5e0!3m2!1sen!2sus!4v1670609462485!5m2!1sen!2sus"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="mt-4 w-full"
        title="pathway_map"
      ></iframe>
    </div>
  );
}
