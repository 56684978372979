import React from "react";
import { Helmet } from "react-helmet";

export default function Newsletter() {
  return (
    <div className="h-screen">
      <Helmet>
        <title>Newsletter | Pathway Resource Center</title>
        <meta
          name="description"
          content="Get signed up for newsletter emails and important updates about Pathway Resource Center."
        />
      </Helmet>
      <div className="mx-auto h-1/2 pt-24 md:w-1/2">
        <div
          className="ml-form-embed embeddedForm"
          data-account="3392599:g3f2s7l6c2"
          data-form="5825070:a8j5w1"
        ></div>
      </div>
    </div>
  );
}
