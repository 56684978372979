import React from "react";
import { Helmet } from "react-helmet";

// import { BiTestTube } from "react-icons/bi";
// import { BsSoundwave } from "react-icons/bs";
// import { GiThreeFriends } from "react-icons/gi";
// import { MdFamilyRestroom } from "react-icons/md";
// import { FaBaby } from "react-icons/fa";

export default function AfterAbortionCare() {
  return (
    <div className="my-4 bg-white font-rubik md:mx-auto md:w-1/2">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
        <title>After Abortion Care | Pathway Resource Center</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="mt-8 mb-2 text-center text-3xl">After Abortion Care</h1>
      <p className="mx-auto mb-4 w-[65%] text-center">
        All our pregnancy services are <span className="italic">free</span> and{" "}
        <span className="italic">confidential</span>
      </p>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <BiTestTube className="mx-auto text-5xl text-[#FFC0CB]" /> */}

        <h1 className="my-4 text-xl font-bold">Healing After Abortion</h1>
        <p className="mx-auto mb-4 w-[80%]">
          At Pathway, in partnership with She Found His Grace, we recognize the
          complicated blend of physical and emotional needs that arise after
          pregnancy termination. Our mission is to provide a supportive
          environment where individuals can find solace, understanding, and
          guidance as they navigate through the aftermath of abortion.
        </p>
        <p className="mx-auto mb-4 w-[80%]">
          Are you experiencing a whirlwind of emotions post-abortion? Whether
          you have left it secret, feelings of anger, shame, guilt, or an
          overwhelming sense of isolation, we understand the complexities
          involved. You're not alone. At She Found His Grace, we offer a
          sanctuary of God's grace, love, and compassion, tailored to help you
          embark on a journey of healing and restoration.
        </p>
        <p className="mx-auto mb-4 w-[80%]">
          Our approach includes online classes designed to ease the processing
          of grief and emotional turmoil post-abortion. Our dedicated team is
          committed to addressing your concerns and providing a safe space for
          open dialogue and support.
        </p>
        <p className="mx-auto mb-6 w-[80%]">
          In addition to emotional support, we acknowledge the importance of
          addressing physical well-being. Below, we outline common physical side
          effects post-abortion and provide guidance on when to seek medical
          attention:
        </p>
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text To Make An Appointment</a>
          </button>
        </div>
        <div className="mx-auto w-[80%]">
          <h1 className="ml-1 text-left">Frequently Asked Questions:</h1>
          <div className="w-full bg-white text-left sm:w-1/2">
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  Common Physical Side Effects?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  Common Physical Side Effects?
                </span>
              </summary>
              <p className="ml-1">
                - Bleeding: Light to moderate bleeding lasting 2-6 weeks. Seek
                emergency medical help for continued heavy bleeding.
                <br />
                - Discharge: Monitor for changes in color and odor. Consult a
                doctor if discharge persists or intensifies.
                <br />
                - Pain/Cramping: Normal pressure in the lower abdomen.
                Persistent or increasing pain warrants medical evaluation.
                <br />
                - Fatigue: Normal tiredness; prioritize rest, hydration, and
                proper nutrition.
                <br />- Infection: Fever may indicate infection; seek medical
                attention promptly.
              </p>
            </details>
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  Common Emotional Side Effects?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  Common Emotional Side Effects?
                </span>
              </summary>
              <p className="ml-1">
                Whether your choice for abortion was met with certainty or
                coercion, the aftermath can manifest in various emotional
                challenges: <br />
                - Depression <br />
                - Sadness <br />
                - Anxiety <br />
                - Eating disorders <br />
                - Substance abuse <br />
                - Intimacy issues <br />
                - Low self-esteem <br />
                - Suicidal thoughts <br />
                - Avoidance behaviors <br />
                - Fears regarding fertility and future pregnancies <br />
                - Nightmares or flashbacks <br />
              </p>
            </details>
          </div>
        </div>
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <BiTestTube className="mx-auto text-5xl text-[#FFC0CB]" /> */}

        <h1 className="my-4 text-xl font-bold">Healing After Abortion</h1>
        <p className="mx-auto mb-4 w-[80%]">
          If you find yourself grappling with any of these emotional side
          effects, hindering your daily activities, seeking help is crucial.
          Many individuals have found liberation and healing through our
          supportive programs. You have the power to choose healing, strength,
          and restoration.
        </p>
        <p className="mx-auto mb-4 w-[80%]">
          At She Found His Grace, we're here to walk alongside you on your
          journey to healing. While we understand the challenges ahead, know
          that you're not alone. Take the first step toward healing by reaching
          out to us today. Please reach out to Pathway or
          www.shefoundhisgrace.org or text our caring and confidential
          helpline&nbsp;
          <span className="text-[#457B9D] underline">
            <a href="tel:+15744005272">574-400-5272</a>
          </span>
        </p>
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <BiTestTube className="mx-auto text-5xl text-[#FFC0CB]" /> */}

        <h1 className="my-4 text-xl font-bold">
          Abortion Pill Reversal: A Second Chance at Choice
        </h1>
        <p className="mx-auto mb-4 w-[80%]">
          At Pathway, we understand the overwhelming fear and stress that often
          accompany an unplanned pregnancy, leading many women to make decisions
          they later regret. However, we also recognize that circumstances can
          change, and decisions can be reversed.
        </p>
        <p className="mx-auto mb-4 w-[80%]">
          If you find yourself in the midst of regret after taking the first
          dose of the abortion pill, know that you are not alone. The question
          arises: Can the abortion pill be reversed? The answer is a resounding
          yes, but time is of the essence.
        </p>

        <p className="mx-auto mb-4 w-[80%]">
          We offer a proven and effective process known as abortion pill
          reversal* that can reverse the effects of the abortion pill and
          provide you with the opportunity to continue your pregnancy. Here's
          how it works:
        </p>

        <p className="mx-auto mb-4 w-[80%]">
          The abortion pill, also known as a chemical abortion, involves a
          two-step medication process. Following the administration of the first
          pill, some women experience regret and seek to reverse the process.
          This is where abortion pill reversal steps in.
        </p>

        <p className="mx-auto mb-4 w-[80%]">
          By utilizing the natural hormone progesterone, our team of medical
          professionals has successfully saved 64-68% of pregnancies through
          abortion pill reversal. We are here to support you every step of the
          way.
        </p>

        <p className="mx-auto mb-4 w-[80%] italic">
          Don't hesitate to reach out to us if you're considering abortion pill
          reversal. Our compassionate team is dedicated to providing you with
          the guidance and support you need during this challenging time. After
          hours please contact{" "}
          <span className="text-[#457B9D] underline">
            <a href="http://abortionpillreversal.com/">
              Abortion Pill Reversal
            </a>
          </span>
          or call our 24/7 Helpline:
          <span className="text-[#457B9D] underline">
            <a href="tel:+18775580333">1-877-558-0333</a>
          </span>
        </p>
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <BiTestTube className="mx-auto text-5xl text-[#FFC0CB]" /> */}

        <h1 className="my-4 text-xl font-bold">Disclaimer</h1>
        <p className="mx-auto mb-4 w-[80%]">
          Abortion Pill Reversal (APR) has demonstrated the ability to increase
          the chances of allowing the pregnancy to continue. However, the
          outcome of your specific reversal attempt cannot be guaranteed. Our
          team will work tirelessly to assist you, but individual results may
          vary.
        </p>
      </div>
      <p className="my-4 mx-auto w-[65%] text-center">
        Pathway does not provide abortions or abortion referrals.
      </p>
    </div>
  );
}
