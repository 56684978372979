import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div className="font-rubik md:mx-auto md:w-1/2 lg:h-[75vh]">
      <Helmet>
        <title>Who We Are | Pathway Resource Center</title>
        <meta
          name="description"
          content="Discover Pathway Resource Center's mission and goal for the community."
        />
      </Helmet>
      {/* <div className="p-10">
        <p className="text-white text-sm lg:text-lg p-2 m-auto max-w-xl bg-[#457B9D] bg-opacity-80 rounded-md">
          {" "}
          “Life is full of choices that carry us down paths leading to different
          destinations. It is Pathway Resource Center’s hope to make the better
          path more obvious by providing knowledge and understanding of choices
          and the consequences of those choices BEFORE they are made and the
          journey begins.” <br />
          -Pathway Board of Directors
        </p>
      </div>
      <h1 className="text-center my-6 text-3xl">Meet Our Team</h1>
      <div className="border-2 m-6 py-2 flex flex-row items-center justify-center gap-10 md:gap-40">
        <img
          src="https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          alt="team1"
          className="w-40 h-32 object-cover"
          loading="lazy"
        />
        <div>
          <h1>Jane Doe</h1>
          <p className="text-xs">Hi this is a bit about me</p>
        </div>
      </div>
      <div className="border-2 m-6 py-2 flex flex-row items-center justify-center gap-10 md:gap-40">
        <img
          src="https://images.unsplash.com/photo-1518020382113-a7e8fc38eac9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=717&q=80"
          alt="team2"
          className="w-40 h-32 object-cover"
          loading="lazy"
        />
        <div>
          <h1>Dee O. Gee</h1>
          <p className="text-xs">Hi this is a bit about me</p>
        </div>
      </div>
      <div className="border-2 m-6 py-2 flex flex-row items-center justify-center gap-10 md:gap-40">
        <img
          src="https://images.unsplash.com/photo-1619895862022-09114b41f16f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          alt="team3"
          className="w-40 h-32 object-cover"
          loading="lazy"
        />
        <div>
          <h1>Janet Doet</h1>
          <p className="text-xs">Hi this is a bit about me</p>
        </div>
      </div> */}
      <h1 className="my-6 text-center text-3xl lg:mt-44">Our Mission</h1>
      <p className="mx-6">
        Pathway's mission is to share the love of Jesus Christ within our
        community by offering sexual risk avoidance and life affirming services
        in order to prevent or alleviate a lifestyle crisis.
      </p>
      <h1 className="my-6 text-center text-3xl">Our Statement of Faith</h1>
      <p className="mx-6 mb-4">
        We believe the Bible to be the inspired, the only infallible,
        authoritative Word of God.
      </p>
      <p className="mx-6 mb-4">
        We believe that there is one God, eternally existent in three persons:
        Father, Son, and the Holy Spirit.
      </p>
      <p className="mx-6 mb-4">
        We believe in the deity of our Lord Jesus Christ, in His virgin birth,
        in His sinless life, in His miracles, in His vicarious and atoning death
        through His shed blood, in His bodily resurrection, in His ascension to
        the right hand of the Father, and in His personal return in power and
        glory.
      </p>
      <p className="mx-6 mb-4">
        We believe that for the salvation of lost and sinful man, regeneration
        of the Holy Spirit is absolutely essential.
      </p>
      <p className="mx-6 mb-4">
        We believe in the present ministry of the Holy Spirit by whose
        indwelling the Christian is enabled to live a godly life.
      </p>
      <p className="mx-6 mb-4">
        We believe in the resurrection of both the saved and the lost; they that
        are saved unto the resurrection of life and they that are lost unto the
        resurrection of damnation.
      </p>
      <p className="mx-6 mb-4">
        We believe in the spiritual unity of believers in our Lord Jesus Christ.
      </p>
    </div>
  );
}
