import React from "react";
import { Helmet } from "react-helmet";

import YouthIssues from "../components/youthissues";
import PathwayBookCarousel from "../components/pathway_book_carousel";

export default function Curriculum() {
  return (
    <div className="text-center font-rubik md:mx-auto md:w-1/2">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
        <title>Youth Curriculum | Pathway Resource Center</title>
        <meta
          name="description"
          content="Learn about bible-based curriculum and lessons for teaching kids life skills and risk avoidance. Lessons cover topics like goal-setting, social media risks, abstinence and drug avoidance, and relationship skills."
        />
      </Helmet>
      <h1 className="my-5 text-center text-3xl">Pathway Curriculum</h1>
      <button className="ml-2 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
        <a href="/curriculum/order">Order Now</a>
      </button>
      <h1 className="py-8 px-8 text-gray-700 md:px-24">
        Every day, young people encounter choices about life and relationships.
        Yet they often lack the information and skills needed to help them make
        wise decisions and avoid risky behavior. At Pathway, we want to prevent
        the pain of unintended consequences that come with uninformed choices so
        we take a proactive approach to real-world issues young people are
        facing through the Pathway Life Skills and Risk Avoidance youth
        development program.
      </h1>
      <PathwayBookCarousel />
      <h1 className="py-8 px-8 text-gray-700 md:px-24">
        This 8-lesson Biblically-based curriculum uses a prevention-based
        education approach designed to give young people the skills necessary to
        make smart choices and avoid risky behaviors that can hurt them
        physically, emotionally, socially, and spiritually.
      </h1>
      <button className="ml-2 rounded-md bg-gray-700 py-3 px-5 font-medium text-white">
        <a href="/curriculum/order">Order Now</a>
      </button>
      <YouthIssues />
    </div>
  );
}
