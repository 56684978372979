import React, { useEffect, useState } from "react";
// import { BiTestTube } from "react-icons/bi";
// import { BsSoundwave } from "react-icons/bs";
// import { GiThreeFriends } from "react-icons/gi";
// import { MdFamilyRestroom } from "react-icons/md";
// import { FaBaby } from "react-icons/fa";

import SmilingTeens from "../assets/smiling_teens.jpg";
import Community from "../assets/community.jpg";

export default function Services() {
  const [posts, setPosts] = useState([
    {
      description:
        "Caffé latte and flat white are definitely the most ordered espresso based drinks in cafés around the world but what are they really? Have you ever wondered the difference between caffé latte vs. flat white? Let's see what makes caffé latte and flat white different from each other!",
      image: "https://images.unsplash.com/photo-1512034400317-de97d7d6c3ed",
      title: "Coffee Roasting Basics: Developing Flavour by Roasting",
    },
  ]);

  // this doesn't shuffle anymore, just for reference
  function xData() {
    /**
     * Shuffle an array
     * @param {Array} array
     * @source https://stackoverflow.com/a/2450976/6940144
     */
    // eslint-disable-next-line
    function shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    }

    const posts = [
      {
        image:
          "https://www.parents.com/thmb/V-UVZhOWav7T8UKtCQqBb117-ac=/900x0/filters:no_upscale():max_bytes(150000):strip_icc():gifv()/1-pregnancy-test-GettyImages-979320160-6a746f650e044d399a2be951f4d6c637.jpg",
        title: "Free Pregnancy Services",
        description:
          "We understand that pregnancy can be a challenging and emotional time, and that's why Pathway is committed to providing expectant mothers with the support and resources they need. From pregnancy testing, ultrasounds, and counseling, to prepared childbirth and baby-care classes, our team of professionals is here to help every step of the way.",
        link: "/pregnancy-services",
      },
      {
        image: SmilingTeens,
        title: "Life Skills/Risk Avoidance Curriculum",
        description:
          "Age-appropriate life skills and sexual risk avoidance curriculum written for middle-school age students and up",
        link: "/curriculum",
      },

      {
        image: Community,
        title: "Community Outreach",
        description:
          "From VBS to Parenting classes, see what we're doing in the area.",
        link: "/community-outreach",
      },
      {
        image:
          "https://images.unsplash.com/photo-1495653797063-114787b77b23?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        title: "Emergency Services",
        description: "Contacts and assistance for a range of circumstances.",
        link: "/emergency-resources",
      },
      // {
      //   image:
      //     "https://images.unsplash.com/photo-1497621122273-f5cfb6065c56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGJpYmxlJTIwY2xhc3N8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60",
      //   title: "Bible-Based Classes",
      //   description:
      //     "Biblically-based classes (SRA, Parenting, Baby-Care, Marriage, etc.)",
      //   link: "curriculum",
      // },
      // {
      //   image:
      //     "https://images.unsplash.com/photo-1514446750685-c27ac87a4403?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHlvdW5nJTIwY291cGxlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //   title: "Purity Conferences",
      //   description: "Purity Conferences",
      //   link: "curriculum",
      // },
      // {
      //   image:
      //     "https://images.unsplash.com/photo-1549994738-a38c87f3453e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWJvcnRpb258ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60",
      //   title: "Sidewalk Advocacy",
      //   description:
      //     "Sidewalk advocate counseling and training (for the abortion clinic setting)",
      //   link: "sidewalk-advocates",
      // },
    ];

    return {
      posts: [
        ...posts,
        // ...shuffle(posts),
        // ...shuffle(posts),
        // ...shuffle(posts),
        // ...shuffle(posts),
      ],
    };
  }

  useEffect(() => {
    setPosts(xData().posts);
  }, []);

  return (
    <div>
      <h1 className="mt-10 text-center text-3xl font-bold text-gray-700">
        What We Offer
      </h1>
      {/* {/* Card List Section */}
      <section className=" py-10 px-12">
        {/* Card Grid */}
        <div className="grid grid-flow-row gap-10  sm:grid-cols-1 lg:grid-cols-2 ">
          {/* <template x-for={post in posts}> */}
          {posts.map((post) => {
            return (
              <div key={post.title}>
                {/* Card Item */}
                <div className="my-2 h-full rounded bg-[#457B9D] shadow-lg shadow-gray-900 duration-300 hover:-translate-y-1 md:mx-auto md:max-w-[60%] lg:max-w-[70%]">
                  {/* Clickable Area */}
                  <a href={post.link} className="h-full cursor-pointer">
                    <figure className="flex h-full flex-col justify-between">
                      {/* Image */}
                      <img
                        src={`${post.image}?auto=format&fit=crop&w=400&q=50`}
                        className="h-72 w-full rounded-t object-cover"
                        alt=""
                        loading="lazy"
                      />
                      <figcaption className="p-4">
                        {/* Title */}
                        <p className="mb-4 text-lg font-bold leading-relaxed text-white dark:text-white">
                          {/* Post Title */}
                          {post.title}
                        </p>
                        {/* Description */}
                        <small className="text-base leading-5 text-white dark:text-white">
                          {/* Post Description */}
                          {post.description}
                        </small>
                      </figcaption>
                      <div className="pr-4 pb-4 text-end">
                        <button className="rounded-md bg-gray-700 py-2 px-3 font-medium text-white">
                          See More
                        </button>
                      </div>
                    </figure>
                  </a>
                </div>
              </div>
            );
          })}
          {/* </template> */}
        </div>
      </section>

      {/* Services */}
      {/* <div>
        <h1 className="my-4 text-center text-white font-bold text-3xl">
          Our Services
        </h1>
        <ul className="flex flex-wrap justify-center ">
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-schoolers bg-center bg-no-repeat lg:w-[40%]">
            <p className="p-2">
              Age-appropriate life skills and sexual risk avoidance curriculum
              written for middle-school age students and up
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white lg:w-[40%]">
            <p className="p-2">
              Pregnancy Services including counseling, pregnancy verifications,
              ultrasounds, and baby-care classes
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white lg:w-[40%]">
            <p className="p-2">
              Parent information meetings on the issues youth are facing and how
              to help
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white lg:w-[40%]">
            <p className="p-2">
              Sidewalk advocate counseling and training (for the abortion clinic
              setting)
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white lg:w-[40%]">
            <p className="p-2">
              Making Life Disciples church training to disciple those going
              through a crisis or unexpected pregnancy situation
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white lg:w-[40%]">
            <p className="p-2">
              Biblically-based classes (SRA, Parenting, Baby-Care, Marriage,
              etc.)
            </p>
          </li>
          <li className="shadow-xl rounded-md mx-8 my-2 p-4 bg-white w-full lg:w-[40%]">
            <p className="p-2">Purity Conferences</p>
          </li>
        </ul>
      </div> */}
    </div>
  );
}
