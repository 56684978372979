import React from "react";
import { Helmet } from "react-helmet";
// import GetInTouchBanner from "../components/getintouchbanner";
import Services from "../components/services";
import Location from "../components/location";
// import { AuctionModal } from "../components/auctionmodal";

// import pathway_plain from "../assets/pathway_plain.webp";

export default function Home() {
  return (
    // <div className="App bg-[#457B9D] font-rubik">
    <div className="App bg-white font-rubik">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
      </Helmet>
      {/* <AuctionModal /> */}
      <div className="flex w-screen flex-col justify-center bg-[#457B9D] lg:h-[50vh]">
        {/* <img
          src={pathway_plain}
          alt="path"
          className="object-contain mx-auto"
        /> */}
        <p className="mx-auto max-w-xl rounded-b-md px-8 pt-8 pb-12 text-sm text-white lg:text-lg ">
          {" "}
          “Life is full of choices that carry us down paths leading to different
          destinations. It is Pathway Resource Center’s hope to make the better
          path more obvious by providing knowledge and understanding of choices
          and the consequences of those choices BEFORE they are made and the
          journey begins.” <br />- Pathway
        </p>
      </div>

      {/* Mission Statement */}

      {/* <GetInTouchBanner /> */}
      {/* Verse */}
      {/* <div className="p-4">
        <p className="text-center">
          “Carefully consider the path for your feet, and all your ways will be
          established.”
          <br />
          Proverbs 4:26
        </p>
      </div> */}

      <Services />
      <Location />
      {/* Verse */}
      <div className="p-4">
        <p className="text-center text-gray-700">
          “Carefully consider the path for your feet, and all your ways will be
          established.”
          <br />
          Proverbs 4:26
        </p>
      </div>
    </div>
  );
}
