import React from "react";
import { Helmet } from "react-helmet";

// import { BiTestTube } from "react-icons/bi";
// import { BsSoundwave } from "react-icons/bs";
// import { GiThreeFriends } from "react-icons/gi";
// import { MdFamilyRestroom } from "react-icons/md";
// import { FaBaby } from "react-icons/fa";

export default function PregnancyServices() {
  return (
    <div className="my-4 bg-white font-rubik md:mx-auto md:w-1/2">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
        <title>Pregnancy Services | Pathway Resource Center</title>
        <meta
          name="description"
          content="Discover the pregnancy support services available and how to access them for a healthy pregnancy. Get access to free services and support during pregnancy. Learn about the early pregnancy symptoms and what they mean for your pregnancy."
        />
      </Helmet>
      <h1 className="mt-8 mb-2 text-center text-3xl">Pregnancy Services</h1>
      <p className="mx-auto mb-6 w-[65%]">
        All our pregnancy services are <span className="italic">free</span> and{" "}
        <span className="italic">confidential</span>
      </p>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <BiTestTube className="mx-auto text-5xl text-[#FFC0CB]" /> */}
        <div className="mx-auto mb-2 h-40 w-40 rounded-full bg-pregnancytest bg-cover bg-center bg-no-repeat"></div>

        <h1 className="text-xl font-bold">Pregnancy Testing</h1>
        <p className="mx-auto mb-4 w-[80%]">
          Whether you know you're pregnant or are questioning, we offer
          pregnancy tests and verification of pregnancy.
        </p>
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text To Make An Appointment</a>
          </button>
        </div>
        <div className="mx-auto w-[80%]">
          <h1 className="ml-1 text-left">Frequently Asked Questions:</h1>
          <div className="w-full bg-white text-left sm:w-1/2">
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  How Do Pregnancy Tests Work?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  How Do Pregnancy Tests Work?
                </span>
              </summary>
              <p className="ml-1">
                All pregnancy tests detect the hormone human chorionic
                gonadotrophin (hCG), which starts to be produced around 6 days
                after conception.
              </p>
            </details>
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  When Should I Take A Pregnancy Test?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  When Should I Take A Pregnancy Test?
                </span>
              </summary>
              <p className="ml-1">
                You can carry out most pregnancy tests from the first day of a
                missed period. If you don't know when your next period is due,
                do the test at least 21 days after you last had sex.
              </p>
            </details>
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  What If My Test Comes Back Positive?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  What If My Test Comes Back Positive?
                </span>
              </summary>
              <p className="ml-1">
                Even if you had a positive at home test, it is still necessary
                to confirm you are pregnant. You should make the appointment as
                soon as possible to get an ultrasound. Reach out to see about
                your options.
              </p>
            </details>
          </div>
        </div>
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        <div className="mx-auto mb-2 h-40 w-40 rounded-full bg-ultrasound bg-cover bg-center bg-no-repeat"></div>

        {/* <BsSoundwave className="mx-auto text-5xl text-[#FFC0CB]" /> */}
        <h1 className="text-xl font-bold">Ultrasounds</h1>
        <p className="mx-auto mb-4 w-[80%]">
          There's so much more to know about your pregnancy, like whether you've
          miscarried or how many weeks you've been pregnant. Information you can
          only get with ultrasound.
        </p>
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text To Make An Appointment</a>
          </button>
        </div>
        <div className="mx-auto w-[75%]">
          <h1 className="ml-1 text-left">Frequently Asked Questions:</h1>
          <div className="w-full bg-white text-left sm:w-1/2">
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  How Does An Ultrasound Work?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  How Does An Ultrasound Work?
                </span>
              </summary>
              <p className="ml-1">
                An ultrasound is painless. The process uses high-frequency sound
                waves to create an image of the inside of your uterus. The sound
                waves are transmitted through a gel placed on your abdomen. Our
                technician rubs a device known as a transducer through the gel.
                The echoes of the sound waves are recorded and transformed into
                video or images.
              </p>
            </details>
            <details className="group">
              <summary className="list-none text-[#457B9D] group-open:text-[#FFC0CB]">
                <span className="ml-1 group-open:hidden">
                  Why Do I Need An Ultrasound?
                </span>
                <span className="ml-1 hidden group-open:inline">
                  Why Do I Need An Ultrasound?
                </span>
              </summary>
              <p className="ml-1">
                1) An ultrasound determines if you have a growing pregnancy, or
                if you might be subject to miscarrying.
              </p>
              <p className="ml-1">
                2) An ultrasound can help determine how long you've been
                pregnant.
              </p>
              <p className="ml-1">
                3) Someimes a pregnancy can develop outside the uterus, in the
                Fallopian tubes. Although rare, this ectopic pregnancy can be
                life-threatening. An ultrasound can help determine if you face
                this risk.
              </p>
            </details>
          </div>
        </div>
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <GiThreeFriends className="mx-auto text-5xl text-[#FFC0CB]" /> */}
        <div className="mx-auto mb-2 h-40 w-40 rounded-full bg-counseling bg-cover bg-center bg-no-repeat"></div>

        <h1 className="text-xl font-bold">Professional Counseling</h1>
        {/* <p className="mb-4 w-[75%] mx-auto">Subtitle</p> */}
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text For Availability</a>
          </button>
        </div>
        {/* <div className="w-[75%] mx-auto">
          <h1 className="text-left ml-1">Frequently Asked Questions:</h1>
          <div className="bg-white w-full text-left sm:w-1/2">
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">1</span>
                <span className="hidden group-open:inline ml-1">1</span>
              </summary>
              <p className="ml-1">1</p>
            </details>
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">2</span>
                <span className="hidden group-open:inline ml-1">2</span>
              </summary>
              <p className="ml-1">2</p>
            </details>
          </div>
        </div> */}
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <MdFamilyRestroom className="mx-auto text-5xl text-[#FFC0CB]" /> */}
        <div className="mx-auto mb-2 h-40 w-40 rounded-full bg-parentingclass bg-cover bg-center bg-no-repeat"></div>

        <h1 className="text-xl font-bold">Prenatal/Parenting Classes</h1>
        <p className="mx-auto mb-4 w-[75%]">
          Prepared Childbirth <br /> Baby Care <br /> Infant CPR
        </p>
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text For Class Schedule</a>
          </button>
        </div>
        {/* <div className="w-[75%] mx-auto">
          <h1 className="text-left ml-1">Frequently Asked Questions:</h1>
          <div className="bg-white w-full text-left sm:w-1/2">
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">1</span>
                <span className="hidden group-open:inline ml-1">1</span>
              </summary>
              <p className="ml-1">1</p>
            </details>
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">2</span>
                <span className="hidden group-open:inline ml-1">2</span>
              </summary>
              <p className="ml-1">2</p>
            </details>
          </div>
        </div> */}
      </div>
      <div className="mx-4 mb-1 border-b py-2 text-center">
        {/* <FaBaby className="mx-auto text-5xl text-[#FFC0CB]" /> */}
        <div className="mx-auto mb-2 h-40 w-40 rounded-full bg-babyboutique bg-cover bg-center bg-no-repeat"></div>
        <h1 className="text-xl font-bold">Baby Boutique</h1>
        <p className="mx-auto mb-4 w-[75%]">
          Attending classes gives you the opportunity to earn points that can be
          used to “purchase” items in the Baby Boutique so you can be ready for
          the big day. Clothes, diapers, wipes, toys and more are available for
          you.
        </p>
        <div className="flex flex-row justify-center">
          <button className="font-sm mx-2 mb-4 rounded-md bg-gray-700 py-1 px-2 text-sm text-white">
            <a href="sms:+18707792229">Text For Class Schedule</a>
          </button>
        </div>
        {/* <div className="w-[75%] mx-auto">
          <h1 className="text-left ml-1">Frequently Asked Questions:</h1>
          <div className="bg-white w-full text-left sm:w-1/2">
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">1</span>
                <span className="hidden group-open:inline ml-1">1</span>
              </summary>
              <p className="ml-1">1</p>
            </details>
            <details className="group">
              <summary className="text-[#457B9D] group-open:text-[#FFC0CB] list-none">
                <span className="group-open:hidden ml-1">2</span>
                <span className="hidden group-open:inline ml-1">2</span>
              </summary>
              <p className="ml-1">2</p>
            </details>
          </div>
        </div> */}
      </div>
      <p className="my-4 mx-auto w-[65%]">
        Pathway does not provide abortions or abortion referrals.
      </p>
    </div>
  );
}
