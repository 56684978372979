import React from "react";
import { Helmet } from "react-helmet";

export default function EmergencyResources() {
  return (
    <div className="py-6 font-rubik">
      <Helmet>
        <link rel="icon" href="favicon.ico" />
        <title>Emergency Resources | Pathway Resource Center</title>
        <meta
          name="description"
          content="Discover resources in your area to help with food, shelter, or rehabilitation."
        />
      </Helmet>
      <h1 className="my-6 text-center text-3xl">Emergency Resources</h1>
      <div className="mx-auto w-[80%] lg:w-[60%] lg:text-center">
        <h1 className="mb-2 font-bold">Addiction Rehabilitation</h1>
        <div className="mx-auto w-full bg-white text-left sm:w-1/2">
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">RU Recovery Program</span>
              <span className="hidden group-open:inline">
                RU Recovery Program
              </span>
            </summary>
            <p className="">Northern Hills Baptist Church</p>
            <p className="">Fridays 7:00 P.M. - Conference Room</p>
            <p className="">(870) 773-3580</p>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                Celebrate Recovery (COTR)
              </span>
              <span className="hidden group-open:inline">
                Celebrate Recovery (COTR)
              </span>
            </summary>
            <p className="">Church On The Rock</p>
            <p className="">Fridays 5:30 P.M. - Connect Cafe</p>
            <p className="">(903) 701-9553</p>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                Celebrate Recovery (First Baptist Moores Lane)
              </span>
              <span className="hidden group-open:inline">
                Celebrate Recovery (First Baptist Moores Lane)
              </span>
            </summary>
            <p className="">First Baptist Moores Lane</p>
            <p className="">Tuesdays 6:00 P.M. - Fusion Center</p>
            <p className="">
              (903) 831-6000 - Call ahead if childcare is needed
            </p>
            <p>
              [Addiction recovery] for both men and women struggling with all
              kinds of addictions.
            </p>
            <p>
              [Addiction recovery] for family members whose loved one is
              struggling with addiction; issues with codependency will be
              discussed.
            </p>
            <p>
              [Emotional healing] for men and women who are struggling with
              negative and destructive emotions from past hurt and/or abuse.
            </p>
            <p>
              [The Landing] a faith-based recovery program for teens who are
              struggling with any form of addiction, eating disorder,
              life-threatening issues or family struggles. Many other topics are
              also addressed. Interactive program format - non-lecture.
            </p>
          </details>
        </div>

        <h1 className="mt-6 mb-2 font-bold">Domestic Abuse</h1>
        <div className="mx-auto w-full bg-white text-left sm:w-1/2">
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                National Domestic Violence Hotline
              </span>
              <span className="hidden group-open:inline">
                National Domestic Violence Hotline
              </span>
            </summary>

            <p className="">(800) 799-7233</p>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                Texarkana Domestic Violence Prevention
              </span>
              <span className="hidden group-open:inline">
                Texarkana Domestic Violence Prevention
              </span>
            </summary>

            <p className="">(903) 793-4357</p>
          </details>
        </div>

        <h1 className="mt-6 mb-2 font-bold">Food</h1>
        <div className="mx-auto w-full bg-white text-left sm:w-1/2">
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                Harvest Regional Food Bank
              </span>
              <span className="hidden group-open:inline">
                Harvest Regional Food Bank
              </span>
            </summary>
            <p className="">(870) 774-1398</p>
            <a
              href="https://harvestregionalfoodbank.org/food-assistance/"
              target="_blank"
              rel="noreferrer"
              className="text-[#457B9D]"
            >
              Website
            </a>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">Church On The Rock</span>
              <span className="hidden group-open:inline">
                Church On The Rock
              </span>
            </summary>
            <p className="">(903) 794-8589</p>
            <p className="">2301 University Ave. Texarkana, TX</p>
            <p className="">Pantry: Tuesday - Friday 9am - 5pm</p>
            <p className="">Notes: Closed 12-1pm for Lunch</p>
            <p className="">Office Door Side of Building Ring Doorbell</p>
          </details>
        </div>

        <h1 className="mt-6 mb-2 font-bold">
          Shelter/Meals/Life Skills Classes
        </h1>
        <div className="mx-auto w-full bg-white text-left sm:w-1/2">
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                Randy Sams Outreach Shelter
              </span>
              <span className="hidden group-open:inline">
                Randy Sams Outreach Shelter
              </span>
            </summary>
            <p className="">(903) 792-7024</p>
            <p className="">402 Oak Street, Texarkana, TX 75501</p>
          </details>
        </div>

        <h1 className="mt-6 mb-2 font-bold">Sex Trafficking</h1>
        <p className="mb-2 lg:mx-auto lg:w-[50%]">
          This crime occurs when a trafficker uses force, fraud or coercion to
          control another person for the purpose of engaging in commercial sex
          acts. Anyone selling sex who is under 18 is legally a trafficking
          victim.
        </p>
        <div className="mx-auto w-full bg-white text-left sm:w-1/2">
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">
                National Human Trafficking Hotline
              </span>
              <span className="hidden group-open:inline">
                National Human Trafficking Hotline
              </span>
            </summary>
            <p className="">(888) 373-7888</p>
            <p className="">402 Oak Street, Texarkana, TX 75501</p>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">Polaris Project</span>
              <span className="hidden group-open:inline">Polaris Project</span>
            </summary>
            <p className="">(202) 790-6300</p>
            <a
              href="https://polarisproject.org/sex-trafficking/"
              target="_blank"
              rel="noreferrer"
              className="text-[#457B9D]"
            >
              Website
            </a>
          </details>
          <details className="group mb-2 border-b">
            <summary className="list-none text-[#457B9D] group-open:text-[#D42B91]">
              <span className="group-open:hidden">Elijah's House</span>
              <span className="hidden group-open:inline">Elijah's House</span>
            </summary>
            <p className="">(903) 949-6733</p>
            <a
              href="https://www.neldashaversministries.org/blank-1"
              target="_blank"
              rel="noreferrer"
              className="text-[#457B9D]"
            >
              Website
            </a>
          </details>
        </div>
      </div>
    </div>
  );
}
